.c-example-box {

    border-radius: 0.25rem;

    margin-bottom: 1rem;

    overflow: hidden
}

  .c-example-box__title {

    padding-left: 0.5rem;

    padding-right: 0.5rem;

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    --tw-bg-opacity: 1;

    background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));

    --tw-text-opacity: 1;

    color: rgb(30 64 175 / var(--tw-text-opacity, 1));

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 600;

    text-transform: uppercase
}

  .c-example-box__content {

    padding: 0.5rem;

    --tw-bg-opacity: 1;

    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));

    font-size: 0.875rem;

    line-height: 1.25rem
}

  .c-example-box__content pre {

    font-size: 0.75rem;

    line-height: 1rem
}

  .c-example-box__content p:first-child {

    margin-top: 0px
}

  .c-example-box__content :last-child {

    margin-bottom: 0px
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL2V4YW1wbGVfYm94X2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7O0lBQUEsc0JBQWM7O0lBQ2QsbUJBQVc7O0lBQ1g7QUFGYzs7RUFLWjs7SUFBQSxvQkFBVzs7SUFBWCxxQkFBVzs7SUFDWCxvQkFBVzs7SUFBWCx1QkFBVzs7SUFDWCxrQkFBa0I7O0lBQWxCLDREQUFrQjs7SUFDbEIsb0JBQW9COztJQUFwQixpREFBb0I7O0lBQ3BCLGtCQUFjOztJQUFkLGlCQUFjOztJQUNkLGdCQUFvQjs7SUFDcEI7QUFOVzs7RUFVWDs7SUFBQSxlQUFVOztJQUNWLGtCQUFrQjs7SUFBbEIsNERBQWtCOztJQUNsQixtQkFBYzs7SUFBZDtBQUZVOztFQUtSOztJQUFBLGtCQUFjOztJQUFkO0FBQWM7O0VBSWQ7O0lBQUE7QUFBVzs7RUFJWDs7SUFBQTtBQUFXIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL2V4YW1wbGVfYm94X2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYy1leGFtcGxlLWJveCB7XG4gIEBhcHBseSByb3VuZGVkO1xuICBAYXBwbHkgbWItNDtcbiAgQGFwcGx5IG92ZXJmbG93LWhpZGRlbjtcblxuICAmX190aXRsZSB7XG4gICAgQGFwcGx5IHB4LTI7XG4gICAgQGFwcGx5IHB5LTE7XG4gICAgQGFwcGx5IGJnLWJsdWUtMjAwO1xuICAgIEBhcHBseSB0ZXh0LWJsdWUtODAwO1xuICAgIEBhcHBseSB0ZXh0LXhzO1xuICAgIEBhcHBseSBmb250LXNlbWlib2xkO1xuICAgIEBhcHBseSB1cHBlcmNhc2U7XG4gIH1cblxuICAmX19jb250ZW50IHtcbiAgICBAYXBwbHkgcC0yO1xuICAgIEBhcHBseSBiZy1ibHVlLTEwMDtcbiAgICBAYXBwbHkgdGV4dC1zbTtcblxuICAgIHByZSB7XG4gICAgICBAYXBwbHkgdGV4dC14cztcbiAgICB9XG5cbiAgICBwOmZpcnN0LWNoaWxkIHtcbiAgICAgIEBhcHBseSBtdC0wO1xuICAgIH1cblxuICAgIDpsYXN0LWNoaWxkIHtcbiAgICAgIEBhcHBseSBtYi0wO1xuICAgIH1cbiAgfVxufVxuIl19 */