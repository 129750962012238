#calc-table tbody tr td,
  #calc-table tfoot tr td {
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(228 228 231 / var(--tw-border-opacity, 1))
}
  #calc-table tbody tr:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1))
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL2hha3VuYS93b3JrX2NhbGN1bGF0b3JfY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHSTs7SUFBQSxxQkFBK0I7SUFBL0Isc0JBQStCO0lBQS9CO0FBQStCO0VBSXJCO0lBQUEsa0JBQWtCO0lBQWxCO0FBQWtCIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL2hha3VuYS93b3JrX2NhbGN1bGF0b3JfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIiNjYWxjLXRhYmxlIHtcbiAgdGJvZHkgdHIgdGQsXG4gIHRmb290IHRyIHRkIHtcbiAgICBAYXBwbHkgYm9yZGVyLXQgYm9yZGVyLWdyYXktMjAwO1xuICB9XG5cbiAgdGJvZHkgdHIge1xuICAgICY6aG92ZXIgeyBAYXBwbHkgYmctYmx1ZS0xMDA7IH1cbiAgfVxufVxuIl19 */